// contexts/TablePaginationContext.tsx
import { createContext } from "react"

// 1. Définition des types
export interface Filter {
	filter: string
}

interface PaginationState {
	sort: string
	cursor: number
	next: boolean
	prev: boolean
	limit: number
	filters: Array<Filter>
}

interface PaginationContextType extends PaginationState {
	setSort: (sort: string) => void
	setCursor: (cursor: number) => void
	setNext: (next: boolean) => void
	setPrev: (prev: boolean) => void
	setLimit: (limit: number) => void
	setFilters: (updater: Array<Filter> | ((prev: Array<Filter>) => Array<Filter>)) => void
}

// 2. Création du Context
export const PaginationContext = createContext<PaginationContextType | null>(null)
