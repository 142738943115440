// components/table/components/ButtonAction.tsx
import { Button } from "@/components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { toast } from "@/components/ui/use-toast"
import React, { ReactNode } from "react"

interface ButtonActionProps {
	tableId: string // Ajout de tableId comme prop
	selectedItems: number[]
	setSelectedItems: (items: number[]) => void
	refresh: () => void
	children: ReactNode
}

export const ButtonAction: React.FC<ButtonActionProps> = ({ tableId, refresh, selectedItems, setSelectedItems, children }: ButtonActionProps) => {
	const handleDelete = async () => {
		const confirmed = confirm(`Are you sure you want to delete ${selectedItems.length === 1 ? "item" : "items"}?`)

		if (!confirmed) return

		try {
			const response = await fetch(`/api/${tableId}/${selectedItems.join(",")}`, {
				method: "DELETE",
			})

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`)
			}

			toast({
				title: `${selectedItems.length === 1 ? "1 item" : selectedItems.length + " items"} deleted successfully.`,
			})

			setSelectedItems([])
			refresh()
		} catch (error) {
			console.error("Failed to delete:", error)
			toast({
				title: "Error",
				description: "Failed to delete items",
				variant: "destructive",
			})
		}
	}

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="flex h-8 w-8 p-0">
					{children}
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end">
				<DropdownMenuItem onClick={handleDelete}>{selectedItems.length === 1 ? "Delete" : "Delete all"}</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
