import useSWR from "swr"
import { useFetchWithAuth } from "@/hooks/API/auth/useFetchWithAuth"
import { TabJobsResponse } from "@/components/table/jobs.types"

export const useTableUsers = () => {
	const fetcherWithAuth = useFetchWithAuth()

	const { data, error, isLoading } = useSWR<TabJobsResponse>(`/api/user_schema`, fetcherWithAuth, {
		keepPreviousData: true,
	})

	return {
		data,
		isLoading,
		error,
	}
}
