import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useAccountMe } from "@/hooks/API/useAccountMe"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"
import { toast } from "@/components/ui/use-toast"
import { Info } from "lucide-react"
import { accountSchema } from "@/pages/Settings/Account/AccountSchema"
import { useAccountStore } from "@/stores/auth-store"
import { useAnimationStore } from "@/stores/animationCSS-store"
import { z } from "zod"

type ProfileFormValues = z.infer<typeof accountSchema>

export default function Account() {
	const { i18n } = useTranslation()
	const { data, isLoading, error } = useAccountMe()
	if (error) console.error(error)
	const { t } = useTranslation(["settings"])
	const [isPasswordVisible, setIsPasswordVisible] = useState(false)
	const { account } = useAccountStore()
	const { animationsEnabled, setAnimationsEnabled } = useAnimationStore()

	// Sync `animationsEnabled` with `account.animation`
	useEffect(() => {
		if (account?.animation !== undefined) {
			setAnimationsEnabled(account.animation) // Set the initial value from the account
		}
	}, [account, setAnimationsEnabled]) // Run this effect when `account` changes

	const defaultValues: Partial<ProfileFormValues> = {
		email: data?.email ?? account?.email ?? "",
		first_name: data?.first_name ?? account?.first_name ?? "",
		last_name: data?.last_name ?? account?.last_name ?? "",
		password: { password: "", confirm: "" },
		role: data?.role ?? account?.role ?? "guest",
		lang: data?.lang ?? account?.lang ?? "fr",
		animation: animationsEnabled,
	}

	const form = useForm<ProfileFormValues>({
		resolver: zodResolver(accountSchema),
		defaultValues,
		mode: "onChange",
	})
	const passwordValue = form.watch("password.password")
	const onSubmit = async (data: ProfileFormValues) => {
		const result = { ...data }
		if (result.password?.password) {
			Object.assign(result, { password: result.password?.password })
		} else {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			delete result.password
		}
		result.animation = animationsEnabled
		delete result.role

		fetch("/api/user/me", {
			method: "PATCH",
			body: JSON.stringify(result),
		})
			.then(res => {
				if (res.ok) {
					toast({
						title: "You submitted the following values:",
						description: (
							<pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
								<code className="text-white">{JSON.stringify(data, null, 2)}</code>
							</pre>
						),
					})
					window.location.reload()
				} else {
					res.json().then(err => {
						for (const [key, value] of Object.entries(err)) {
							form.setError(key as keyof ProfileFormValues, {
								type: "onChange",
								message: value as string,
							})
						}
						toast({
							title: "An error occurred",
							description: "Please try again later. " + JSON.stringify(err),
						})
					})
				}
			})
			.catch(err => {
				console.error("Error sending PATCH request:", err)
			})
			.finally(() => {
				console.log("end of PATCH")
			})
	}

	useEffect(() => {
		form.reset(data)
	}, [data, form])

	useEffect(() => {
		i18n.changeLanguage(data?.lang).then()
	}, [data, i18n])

	useEffect(() => {
		form.setValue("animation", animationsEnabled)
	}, [animationsEnabled, form])

	if (isLoading) {
		return <div>Loading...</div>
	}

	return (
		<div className="max-w-xl">
			<Form {...form}>
				<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
					<FormField
						control={form.control}
						name="first_name"
						render={({ field, formState }) => {
							return (
								<FormItem>
									<FormLabel>{t("settings:account.firstName")}</FormLabel>
									<FormControl>
										<Input placeholder="John" {...field} />
									</FormControl>
									<FormMessage>{formState?.errors?.first_name?.message}</FormMessage>
								</FormItem>
							)
						}}
					/>
					<FormField
						control={form.control}
						name="last_name"
						render={({ field, formState }) => (
							<FormItem>
								<FormLabel>{t("settings:account.lastName")}</FormLabel>
								<FormControl>
									<Input placeholder="Doe" {...field} />
								</FormControl>
								<FormMessage>{formState?.errors?.last_name?.message}</FormMessage>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="password.password"
						render={({ field, formState }) => (
							<FormItem>
								<FormLabel htmlFor="password">{t("settings:account.newPassword")}</FormLabel>
								<div className="flex items-center space-x-2">
									<FormControl>
										<Input
											type={isPasswordVisible ? "text" : "password"}
											id="password"
											placeholder={t("settings:account.placeholderPassword")}
											autoComplete="new-password"
											{...field}
										/>
									</FormControl>
									<div className="flex items-center space-x-2">
										<Switch id="password-switch" onCheckedChange={setIsPasswordVisible} />
										<Label htmlFor="password-switch">{t("settings:account.ShowPassword")}</Label>
									</div>
								</div>
								<FormMessage>{formState?.errors?.password?.message}</FormMessage>
							</FormItem>
						)}
					/>

					{passwordValue !== "" && passwordValue !== undefined ? (
						<FormField
							control={form.control}
							name="password.confirm"
							render={({ field, formState }) => (
								<FormItem>
									<div className="flex items-center space-x-2">
										<FormControl>
											<Input type={isPasswordVisible ? "text" : "password"} placeholder={t("settings:account.confirmPassword")} {...field} />
										</FormControl>
										<div className="flex items-center min-w-20"></div>
									</div>
									<FormMessage>{formState?.errors?.password?.message}</FormMessage>
								</FormItem>
							)}
						/>
					) : null}

					<FormField
						control={form.control}
						name="email"
						render={({ field, formState }) => (
							<FormItem>
								<FormLabel>{t("settings:account.email")}</FormLabel>
								<FormControl>
									<Input placeholder="john.doe@gmail.com" {...field} />
								</FormControl>
								<FormMessage>{formState?.errors?.email?.message}</FormMessage>
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="animation"
						render={({ field, formState }) => (
							<FormItem>
								<FormLabel>{t("settings:account.animation")}</FormLabel>
								<FormControl>
									<div className="flex items-center space-x-2">
										<Label htmlFor="animation">
											<Info />
										</Label>
										<Switch
											id="animation"
											checked={!animationsEnabled}
											onCheckedChange={checked => {
												setAnimationsEnabled(!checked)
												field.onChange(!checked)
											}}
										/>
									</div>
								</FormControl>
								<FormMessage>{formState.errors?.animation?.message}</FormMessage>
							</FormItem>
						)}
					/>

					<FormField
						control={form.control}
						name="role"
						disabled={true}
						render={({ field, formState }) => (
							<FormItem>
								<FormLabel>{t("settings:account.role")}</FormLabel>
								<FormControl>
									<Input {...field} />
								</FormControl>
								<FormMessage>{formState?.errors?.role?.message}</FormMessage>
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="lang"
						render={({ field }) => (
							<FormItem>
								<FormLabel>{t("settings:account.language")}</FormLabel>
								<Select onValueChange={field.onChange} defaultValue={data?.lang}>
									<FormControl>
										<SelectTrigger>
											<SelectValue placeholder="Select your language to display" />
										</SelectTrigger>
									</FormControl>
									<SelectContent>
										<SelectItem value="en">EN</SelectItem>
										<SelectItem value="fr">FR</SelectItem>
									</SelectContent>
								</Select>
								<FormMessage />
							</FormItem>
						)}
					/>
					<Button type="submit">{t("settings:btn.save")}</Button>
				</form>
			</Form>
		</div>
	)
}
