import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export function formatDate(timestamp: number) {
	return new Date(1000 * timestamp).toLocaleDateString()
}

export function throttle<T extends unknown[]>(fn: (...args: T) => void, limit: number) {
	let lastCall = 0
	return function (...args: T) {
		const now = Date.now()
		if (now - lastCall >= limit) {
			lastCall = now
			fn(...args)
		}
	}
}

// Utilitaires API
export const debounce = <Args extends unknown[], R>(fn: (...args: Args) => R, delay: number) => {
	let timeoutID: NodeJS.Timeout
	return (...args: Args) => {
		clearTimeout(timeoutID)
		timeoutID = setTimeout(() => fn(...args), delay)
	}
}

export const createLocker = () => {
	let mutex = Promise.resolve()
	return () =>
		new Promise<() => void>(resolve => {
			const previous = mutex
			let released = false
			mutex = new Promise(innerResolve => {
				previous.then(() => {
					if (!released) {
						released = true
						resolve(() => {
							innerResolve(undefined)
						})
					}
				})
			})
		})
}
