// components/table/TablePaginationProvider.tsx
import { PropsWithChildren, useState, useCallback } from "react"
import { PaginationContext, Filter } from "./TablePaginationContext"

export const TablePaginationProvider = ({ children }: PropsWithChildren) => {
	// 1. Définition des states
	const [sort, setSort] = useState("id,asc")
	const [cursor, setCursor] = useState(0)
	const [next, setNext] = useState(false)
	const [prev, setPrev] = useState(false)
	const [limit, setLimit] = useState(10)
	const [filters, setFilters] = useState<Array<Filter>>([])

	// 2. Handler pour setFilters avec support pour fonction updater
	const handleSetFilters = useCallback((updater: Array<Filter> | ((prev: Array<Filter>) => Array<Filter>)) => {
		if (typeof updater === "function") {
			setFilters(updater)
		} else {
			setFilters(updater)
		}
	}, [])

	// 3. Création de l'objet de contexte
	const value = {
		sort,
		setSort,
		cursor,
		setCursor,
		next,
		setNext,
		prev,
		setPrev,
		limit,
		setLimit,
		filters,
		setFilters: handleSetFilters,
	}

	return <PaginationContext.Provider value={value}>{children}</PaginationContext.Provider>
}
