// hooks/useTablePagination.ts
import { useContext } from "react"
import { PaginationContext } from "../contexts/TablePaginationContext"

export const useTablePagination = () => {
	const context = useContext(PaginationContext)
	if (!context) {
		throw new Error("useTablePagination must be used within a TablePaginationProvider")
	}
	return context
}
