import { Link } from "react-router-dom"
import { ChevronLeft, ChevronRight, Home, LineChart, Package, PieChart, Settings, ShoppingCart, Users2 } from "lucide-react"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { Button, buttonVariants } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import clsx from "clsx"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

interface NavPanelProps {
	openNav: boolean
	setOpenNav: (openNav: (currentState: boolean) => boolean) => void
}

export function NavPanel({ openNav, setOpenNav }: NavPanelProps) {
	const { t } = useTranslation(["layout"])
	const navItems = useMemo(
		() => [
			{ to: "/home", icon: Home, text: t("layout:sideMenu.home") },
			{ to: "/dashboard", icon: PieChart, text: t("layout:sideMenu.dashboard") },
			{ to: "/orders", icon: ShoppingCart, text: t("layout:sideMenu.orders") },
			{ to: "/products", icon: Package, text: t("layout:sideMenu.products") },
			{ to: "/customers", icon: Users2, text: t("layout:sideMenu.customers") },
			{ to: "/analytics", icon: LineChart, text: t("layout:sideMenu.analytics") },
		],
		[t],
	)

	return (
		<div className={`fixed inset-y-0 z-10 flex flex-col border-r bg-background`}>
			<nav
				className={clsx("flex flex-col h-full justify-between gap-2 px-2 py-4", {
					"items-start": openNav,
					"items-center": !openNav,
				})}
			>
				<div className="w-full h-[3.5rem]">
					<img className="col-span-2 max-h-8 w-auto object-contain lg:col-span-1" src={!openNav ? "/vite.svg" : "/transistor-logo-gray-900.svg"} alt="Transistor" />

					<TooltipProvider key={"logo"}>
						<Tooltip>
							<TooltipTrigger asChild>
								<div className="relative w-full h-12">
									<Button
										onClick={() => setOpenNav(currentState => !currentState)}
										className={clsx(
											"absolute top-2 px-2 h-8 w-8 inline-flex items-center justify-center transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-background hover:bg-secondary/80 rounded-full transform shadow",
											{
												"-right-6": openNav,
												"left-7": !openNav,
											},
										)}
									>
										{openNav ? <ChevronLeft className="text-muted-foreground" size={14} /> : <ChevronRight className="text-muted-foreground" size={14} />}
									</Button>
								</div>
							</TooltipTrigger>
							<TooltipContent side="right">{openNav ? "Close" : "Open"}</TooltipContent>
						</Tooltip>
					</TooltipProvider>

					<div className="grid gap-1 w-full top-12">
						{navItems.map((item, index) =>
							openNav ? (
								<div key={index}>
									<Link
										to={item.to}
										className={cn(
											buttonVariants({ variant: "ghost", size: "sm" }),
											"h-9 w-full flex items-center justify-start rounded-lg",
											"dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white",
										)}
									>
										<item.icon className="h-4 w-4" />
										<h2 className="ml-2 text-base">{item.text}</h2>
										<span className="sr-only">{item.text}</span>
									</Link>
								</div>
							) : (
								<TooltipProvider key={"menu + " + item.text}>
									<Tooltip>
										<TooltipTrigger asChild>
											<Link
												to={item.to}
												className={cn(
													buttonVariants({ variant: "ghost", size: "icon" }),
													"h-9 w-9",
													"dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white",
												)}
											>
												<item.icon className="h-4 w-4" />
												<span className="sr-only">{item.text}</span>
											</Link>
										</TooltipTrigger>
										<TooltipContent side="right">{item.text}</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							),
						)}
					</div>
				</div>

				<TooltipProvider key={"settings"}>
					<Tooltip>
						<TooltipTrigger asChild>
							<Link
								to="/settings"
								className={cn(
									buttonVariants({ variant: "ghost", size: "icon" }),
									"h-9 w-9",
									"dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white",
								)}
							>
								<Settings className="h-4 w-4" />
								<span className="sr-only">Settings</span>
							</Link>
						</TooltipTrigger>
						<TooltipContent side="right">{t("layout:topMenu.settings")}</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</nav>
		</div>
	)
}
