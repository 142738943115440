import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { ArrowDownIcon, ArrowUpDown, ArrowUpIcon, CircleX, TextSearch } from "lucide-react"
import { useTablePagination } from "../hooks/useTablePagination"
import React, { useEffect, useState } from "react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { cn, debounce } from "@/lib/utils"
import { Checkbox } from "@/components/ui/checkbox"

export function ColumnHeader({
	label,
	id,
	className,
	allSelected,
	toggleSelectAll,
	setIsInitialized,
}: {
	id: string
	label: string
	tableId: string
	className?: string
	allSelected?: boolean
	toggleSelectAll?: () => void
	setIsInitialized: (value: boolean) => void
}) {
	const { sort, setSort, setFilters, filters, setCursor, setNext, setPrev } = useTablePagination()

	const sortId = sort.split(",")[0]
	const sortDirection = sort.split(",")[1]
	const isSortedAsc = id === sortId && sortDirection === "asc"
	const isSortedDesc = id === sortId && sortDirection === "desc"
	const [showFilter, setShowFilter] = useState(false)
	const [filter, setFilter] = useState("")

	useEffect(() => {
		const updateFilters = debounce(() => {
			setFilters(prevFilters => {
				const newFilters = prevFilters.filter(f => !f.filter.startsWith(`${id}`))
				if (filter) {
					newFilters.push({ filter: `${id}:${filter}` })
				}
				return newFilters
			})
		}, 300)

		updateFilters()
	}, [filter, id, setFilters])

	const icon = isSortedAsc ? (
		<ArrowUpIcon className="m-1 opacity-1 group-hover:opacity-100 group-hover:visible transition-opacity duration-200" />
	) : isSortedDesc ? (
		<ArrowDownIcon className="m-1 opacity-1 group-hover:opacity-100 group-hover:visible transition-opacity duration-200" />
	) : (
		<ArrowUpDown className="m-1 opacity-0 group-hover:opacity-100 group-hover:visible transition-opacity duration-200" />
	)

	const toggleSorting = (sorted: string) => {
		setSort(`${id},${sorted}`)
	}

	const toggleVisibilityIsFiltering = () => {
		setShowFilter(!showFilter)
	}

	const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value
		setFilter(inputValue)
	}

	const handleClearFilter = () => {
		setFilters(filters.filter((f: { filter: string }) => !f.filter.startsWith(`${id}.contains`)))
		setShowFilter(false)
		setFilter("")

		setIsInitialized(false)
		setCursor(0)
		setNext(false)
		setPrev(false)
	}

	if (id === "tool") {
		return (
			<div className={cn("flex items-center", className)}>
				<span className="text-sm"></span>
			</div>
		)
	}

	if (id === "checkbox") {
		return (
			<div className={cn("flex items-center", className)}>
				<span className="text-sm">
					<Checkbox id="terms" checked={allSelected} onCheckedChange={toggleSelectAll} />
				</span>
			</div>
		)
	}

	return (
		<>
			<div className={cn("flex items-center", className)}>
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button variant="ghost" size="sm" className={cn("w-full flex h-8 p-0 data-[state=open]:bg-accent group", className)}>
							{className === "text-right" ? (
								<div className="w-full flex justify-end p-1">
									<span className="flex items-center space-x-1 text-sm w-5 h-5">{icon}</span>
									<span className="flex items-center space-x-1 text-sm">{label}</span>
								</div>
							) : (
								<div className="w-full flex justify-start p-1">
									<span className="flex items-center space-x-1 text-sm">{label}</span>
									<span className="flex items-center space-x-1 text-sm w-5 h-5">{icon}</span>
								</div>
							)}
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="start">
						<DropdownMenuItem onClick={() => toggleSorting("asc")}>
							<ArrowUpIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
							Asc
						</DropdownMenuItem>
						<DropdownMenuItem onClick={() => toggleSorting("desc")}>
							<ArrowDownIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
							Desc
						</DropdownMenuItem>
						<DropdownMenuSeparator />
						<DropdownMenuItem onClick={() => toggleVisibilityIsFiltering()}>
							<TextSearch className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
							Filter
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
			{showFilter && (
				<div className="w-full mb-2 relative">
					<Input placeholder={`Filter`} className="p1 pr-10" onChange={handleFilter} value={filter} />
					<span onClick={handleClearFilter} className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-400 hover:text-gray-600">
						<CircleX className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
					</span>
				</div>
			)}
		</>
	)
}
